<template>
  <a-select
    style="width: 100%"
    :getPopupContainer="getPopupContainer"
    :placeholder="placeholder"
    :disabled="disabled"
    v-model="getValueSting"
    allowClear
    :mode="mode"
    :filter-option="false"
    showSearch
    :not-found-content="loading ? undefined : null"
    @search="fetchUser"
    @change="handleInput"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option :value="-1">全部</a-select-option>
    <a-select-option
      v-for="item in dictOptions"
      :key="item.id"
      :value="item.id"
    >
      {{ item.name }}
    </a-select-option>
    <div slot="dropdownRender" slot-scope="menu">
      <v-nodes :vnodes="menu" />
      <a-divider style="margin: 4px 0" />
      <div @click.stop.prevent="" @mousedown="(e) => e.preventDefault()">
        <a-pagination
          size="small"
          simple
          :total="ipagination.total"
          :pageSize="ipagination.pageSize"
          :current="ipagination.current"
          :hideOnSinglePage="false"
          @change="onPageChange"
          style="text-align: center; padding: 4px 8px; cursor: pointer"
        >
        </a-pagination>
      </div>
    </div>
  </a-select>
</template>

<script>
import moment from "moment";
import { postAction } from "@/api/modules/manage";
import debounce from "lodash/debounce";

export default {
  name: "JMultiSelect",
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    url: String,
    placeholder: String,
    triggerChange: Boolean,
    disabled: Boolean,
    value: [String, Number, Array],
    params: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "default",
    }, //'default' | 'multiple' | 'tags' | 'combobox'
    trigger: {
      type: Boolean,
      default: true,
    },
    getPopupContainer: {
      type: Function,
      default: (node) => node.parentNode,
    },
  },
  data() {
    this.initDictData = debounce(this.initDictData, 800);
    return {
      dictOptions: [],
      mode: "", //'default' | 'multiple' | 'tags' | 'combobox'
      hasValue: false,
      searchValue: "",
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["20", "30", "50"],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      loading: false,
    };
  },
  watch: {
    url: {
      immediate: true,
      handler() {
        this.hasValue = false;
        let data = Object.assign({}, { page: 1 }, this.params);
        this.initDictData(data);
      },
    },

    value: {
      immediate: true,
      handler(val, oldVal) {
        if (!oldVal && val) {
          console.log("initDictData");
          this.hasValue = true;
          let data = Object.assign({}, { page: 1 }, this.params);
          this.initDictData(data);
        }
      },
    },
    params: {
      immediate: true,
      handler() {
        console.log(this.params);
        if (!this.params) {
          return;
        }
        this.hasValue = false;
        let data = Object.assign({}, { page: 1 }, this.params);
        this.initDictData(data);
        // console.log('url')
      },
    },
  },
  created() {
    if (!this.type || this.type === "default") {
      this.mode = "default";
    } else {
      this.mode = this.type;
    }
  },
  computed: {
    getValueSting: {
      // console.log(this.value)
      // update-begin author:wangshuai date:20200601 for: 不显示placeholder的文字 ------
      // 当有null或“” placeholder不显示
      get() {
        if (this.mode == "multiple") {
          if (this.value) {
            return Array.isArray(this.value)
              ? this.value
              : this.value.split(",");
          } else {
            return [];
          }
          // return this.value ? this.value : []
        } else {
          return this.value != null ? this.value : undefined;
        }
      },
      set() {},
    },
  },
  methods: {
    fetchUser(value) {
      console.log(value);
      this.searchValue = value;
      this.ipagination.current = 1;
      let data = Object.assign({}, { page: 1, name: value }, this.params);
      this.initDictData(data);
    },
    async initDictData(param) {
      let arr;
      console.log("initDictDatainitDictData");
      if (!this.trigger) {
        return;
      }
      if (this.mode == "multiple") {
        if (this.value) {
          arr = Array.isArray(this.value) ? this.value : this.value.split(",");
        } else {
          arr = [];
        }
      } else {
        arr = this.value ? [this.value] : [];
      }
      if (this.hasValue && arr.length > 10) {
        this.ipagination.pageSize = arr.length;
      }
      let data = {
        ...param,
        page_size: this.ipagination.pageSize,
        top_id_array: this.hasValue ? arr : [],
      };
      // console.log(data)
      this.dictOptions = [];
      this.loading = true;
      let result = await postAction(this.url, data);
      console.log(result);
      if (result.code==0) {
        this.$nextTick(() => {
          this.dictOptions = result.data.data;
        });
        this.ipagination.total = result.data.total;
      }
      this.loading = false;
    },
    handleInput(e) {
      let val, val1;
      val = e;
      if (this.triggerChange) {
        this.$emit("change", val);
        // console.log(this.dictOptions)
        if (this.dictOptions.length > 0) {
          this.dictOptions.map((item) => {
            if (item.id == val) {
              val1 = item;
            }
          });
        }
        this.$emit("change1", val1);
      } else {
        this.$emit("input", val);
        if (this.dictOptions.length > 0) {
          this.dictOptions.map((item) => {
            if (item.id == val) {
              val1 = item;
            }
          });
        }
        this.$emit("input1", val1);
      }
    },
    onPageChange(e) {
      console.log(e);
      this.ipagination.current = e;
      this.initDictData({ page: e, name: this.searchValue });
    },
  },
};
</script>

<style scoped>
</style>