<template>
  <div class="detail-view" v-if="visible">
    <div class="close-view" @click="close">
      <a-icon type="close" style="fontSize: 2.3vh;color: #fff"/>
    </div>
    <div class="search-load" v-if="detail_loading">
      <a-spin tip="加载中...">
        <a-icon slot="indicator" type="loading" style="font-size: 3.25vh" spin />
      </a-spin>
    </div>
    <div class="main" v-else>
      <div class="left">
        <a-collapse :default-active-key="active_key" :bordered="false" expand-icon-position="right">
          <template #expandIcon="props">
            <a-icon type="right" :rotate="props.isActive ? 90 : 0" style="fontSize: 2vh"/>
          </template>
          <a-collapse-panel key="1" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>基本信息</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="base">
              <div class="head">
                <span class="title">{{name}} </span>
              </div>
              <div class="head">
                <span class="logo" v-for="(item, index) in labels" :key="index">#{{item}}</span>
              </div>
              <div class="no">
                <div>事项编号: {{code}}</div>
                <div class="hot">
                  <img src="../../../assets/img/hot-icon2.png" alt="">
                  <span>{{view_count}}</span>
                </div>
              </div>
              <div style="white-space: pre;">区分: {{project_name}}</div>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="2" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>违则</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="content" v-html="violation_rule"></div>
          </a-collapse-panel>
          <a-collapse-panel key="3" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>罚则</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="content" v-html="penalty_rule"></div>
          </a-collapse-panel>
          <a-collapse-panel key="4" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>赋权范围</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="content">{{empower_range}}</div>
          </a-collapse-panel>
          <a-collapse-panel key="5" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>自由裁量权行使标准</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="content">{{discretionary_power_rule}}</div>
          </a-collapse-panel>
          <a-collapse-panel key="6" >
            <template slot="header">
              <div class="flex-item">
                <div class="vertical-icon"></div>
                <span>相关法律、法规</span>
                <div class="lineae"></div>
              </div>
            </template>
            <div class="list">
              <div class="law-item" @click="checkLawDetail(item)" v-for="(item, index) in laws" :key="index">
                <span class="title">{{item.name}}</span>
                <div class="btn">查看</div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
      <div class="right">
        <div class="title">
          <div class="flex-item">
            <div class="vertical-icon"></div>
            <span>相关案例（{{photo_list.length}}）</span>
          </div>
        </div>
        <div class="tip" v-if="photo_list.length==0">暂无案例</div>
        <div class="check-photo" v-else>
          <div class="photo-box" v-for="(item, index) in photo_list" :key="index">
            <div class="photo" @click="preview(index)">
              <img :src="item.cover_url" alt="">
            </div>
            <div class="type-box">
              <span class="logo" v-for="(it, index2) in item.labels" :key="index2">#{{it}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <laws-modal ref="lawsModal"></laws-modal>
  </div>
</template>

<script>
import { api as viewerApi } from "v-viewer"
import LawsModal from './lawsModal.vue'
export default {
  name:'resultDetailView',
  data() {
    return {
      visible: false,
      detail_loading: false,
      active_key: ['1', '2', '3', '4', '5', '6'], //展开的面板key
      code: '', //事项编号
      view_count: 0, //浏览热度
      name: '', //事项名称
      project_name: '', // 区分
      violation_rule: '', //违则
      penalty_rule: '', //罚则
      empower_range: '', //赋权范围
      discretionary_power_rule: '', //自由裁量权行使标准
      labels: [], //标签数组
      laws: [], //法规数组
      image_list: [
        {
          url:require('../../../assets/img/exp.png'),
        },
        {
          url:require('../../../assets/img/bg1.png'),
        },
      ],
      photo_list: []
    }
  },
  components: {
    LawsModal
  },
  mounted() {
    
  },
  methods: {
    // 初始化
    init(id, tdid) {
      this.visible = true;
      this.code = ''; //事项编号
      this.view_count = 0; //浏览热度
      this.name = ''; //事项名称
      this.project_name = '无'; // 区分
      this.violation_rule = '无'; //违则
      this.penalty_rule = '无'; //罚则
      this.empower_range = '无'; //赋权范围
      this.discretionary_power_rule = '无'; //自由裁量权行使标准
      this.labels = []; //标签数组
      this.laws = []; //法规数组
      this.photo_list = [];
      this.image_list = [];
      this.getData(id, tdid);
    },
    // 获取详情数据
    getData(id, tdid) {
      const that = this;
      this.detail_loading = true;
      this.$api.search.getSearchItemDetail({id: id, code: tdid}).then(res =>{
        console.log(res)
        if(res.code==0){
          this.code = res.data.code;
          this.view_count = res.data.view_count;
          this.name = res.data.name;
          this.violation_rule = res.data.violation_rule;
          this.penalty_rule = res.data.penalty_rule;
          this.empower_range = res.data.empower_range;
          this.discretionary_power_rule = res.data.discretionary_power_rule;
          this.labels = res.data.labels;
          this.laws = res.data.laws;
          this.project_name = res.data.project_name;
          this.photo_list = res.data.examples;
          this.image_list = this.photo_list.map((item)=>{
            return { 'src': item.file_url, 'data-source': item.file_url }
          })
        }else{
          this.$message.warning(res.message||res.data.message)
        }
      }).finally(() => {
        that.detail_loading = false;
      })
    },
    // 查看法规详情
    checkLawDetail(item) {
      this.$nextTick(()=>{
        this.$refs.lawsModal.init(item)
      })
    },
    // 图片预览
    preview(index) {
      const $viewer = viewerApi({
        options: {
          toolbar: true,
          url: 'data-source',
          initialViewIndex: index
        },
        images: this.image_list
      })
    },
    // 关闭页面
    close(){
      this.visible = false;
      this.$emit('close')
    }

  }
}
</script>

<style lang="scss" scoped>
.detail-view {
  flex: 1;
  height: 80.5vh;
  margin-right: 5.2vh;
  border-radius: 1.5vh;
  background-color: rgba(9, 123, 244, .12);
  padding: 3vh;
  padding-right: 1vh;
  position: relative;
  overflow: hidden;
  .close-view {
    position: absolute;
    right: 2vh;
    top: 3vh;
    cursor: pointer;
  }
  .search-load {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: hidden;
    .left {
      flex: 3;
      height: 100%;
      overflow-x: hidden;
      margin-right: 6.4vh;
      .flex-item {
        display: flex;
        align-items: center;
        .vertical-icon {
          width: 0.28vh;
          height: 2.8vh;
          margin-right: 1vh;
          background: linear-gradient(180deg, rgba(141, 226, 238, 0) 0%, #8DE2EE 30.21%, #8DE2EE 66.67%, rgba(141, 226, 238, 0) 100%);
        }
        span {
          font-size: 1.86vh;
          font-weight: 600;
        }
        .lineae {
          flex: 1;
          height: 0.15vh;
          margin: 0 1.5vh;
          border-top: 0.15vh dashed;
          border-color: rgba(255, 255, 255, .2);
        }
      }
      ::v-deep .ant-collapse-borderless > .ant-collapse-item {
        border: 0;
      }
      ::v-deep .ant-collapse-borderless {
        background-color: transparent;
      }
      ::v-deep .ant-collapse {
        color: #fff;
        font-size: 1.3vh;
      }
      ::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: #fff;
      }
      ::v-deep .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 1.12vh 1.49vh;
        padding-right: 3.72vh;
      }
      .base {
        width: 100%;
        color: #fff;
        font-size: 1.68vh;
        padding-left: 1vh;
        .head {
          width: 100%;
          // line-height: 3.5vh;
          display: flex;
          flex-wrap: wrap;
          .logo {
            padding: 0.47vh 1vh;
            background: rgba(8, 122, 240, 0.24);
            border-left: 0.5vh solid #3395FE;
            border-right: 0.5vh solid #3395FE;
            margin-right: .8vh;
            margin-bottom: 1vh;
            font-size: 1.49vh;
          }
          .title {
            font-size: 2.25vh;
            font-weight: 600;
            margin-bottom: 1vh;
          }
        }
        .no {
          width: 100%;
          display: flex;
          align-items: center;
          line-height: 3.35vh;
          margin-bottom: 1.4vh;
          padding-right: 1vh;
          .hot {
            display: flex;
            align-items: center;
            margin-left: 3vh;
            img {
              width: 1.3vh;
              margin-right: .8vh;
            }
          }
        }
      }
      .content {
        white-space: pre-line;
      }
      ::v-deep .content a{
        color: #fff;
      }
      ::v-deep .content a:hover{
        color: #1890ff;
      }
      .content,
      .list {
        color: #fff;
        font-size: 1.68vh;
        line-height: 3.5vh;
        padding-left: 1vh;
      }
      .list {
        width: 100%;
        .law-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-right: 2vh;
          .btn {
            color: #33DCFE;
            cursor: pointer;
            margin-left: 2vh;
          }
          .title {
            flex: 1;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }
          .title:hover {
            color: #FDB933;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      overflow-x: hidden;
      padding-right: 4vh;
      color: #fff;
      .tip {
        font-size: 1.68vh;
        line-height: 3vh;
      }
      .flex-item {
        display: flex;
        align-items: center;
        margin-bottom: 1vh;
        .vertical-icon {
          width: 0.28vh;
          height: 2.8vh;
          margin-right: 1vh;
          background: linear-gradient(180deg, rgba(141, 226, 238, 0) 0%, #8DE2EE 30.21%, #8DE2EE 66.67%, rgba(141, 226, 238, 0) 100%);
        }
        span {
          font-size: 1.86vh;
        }
      }
      .photo-box {
        width: 100%;
        .photo {
          width: 100%;
          max-height: 20vh;
          border: 0.25vh solid #3395FE;
          margin-bottom: 1vh;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .type-box {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .logo {
            padding: 0.47vh 1vh;
            background: rgba(8, 122, 240, 0.24);
            border-left: 0.5vh solid #3395FE;
            border-right: 0.5vh solid #3395FE;
            margin-right: .8vh;
            margin-bottom: 1vh;
            font-size: 1.49vh;
          }
        }
      }
    }
  }
  
}
</style>
