<template>
  <div :id="containerId" style="position: relative">
    <a-upload
      name="file"
      :multiple="true"
      :action="uploadAction"
      :headers="headers"
      :data="{'biz':bizPath}"
      :fileList="fileList"
      :beforeUpload="beforeUpload"
      @change="handleChange"
      :disabled="disabled"
      :returnUrl="returnUrl"
      :accept="accept"
      :showUploadList="false"
      :class="{'uploadty-disabled':disabled}">
      <template>
        <img class="upload-box" src="../../../assets/img/camera.png" alt="">
      </template>
    </a-upload>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { getFileMd5 } from '@/utils/util'
  const uidGenerator=()=>{
    return '-'+parseInt(Math.random()*10000+1,10);
  }
  const getFileName=(path)=>{
    if(path.lastIndexOf("\\")>=0){
      let reg=new RegExp("\\\\","g");
      path = path.replace(reg,"/");
    }
    return path.substring(path.lastIndexOf("/")+1);
  }
  export default {
    name: 'JUpload',
    data(){
      return {
        uploadAction:process.env.VUE_APP_BASE_API+"api/common/attachment/upload",
        headers:{},
        fileList: [],
        newFileList: [],
        uploadGoOn:true,
        previewVisible: false,
      }
    },
    props:{
      accept:{
        type:String,
        required:false,
        default:null
      },
      /*这个属性用于控制文件上传的业务路径*/
      bizPath:{
        type:String,
        required:false,
        default:"temp"
      },
      value:{
        type:[String,Array],
        required:false
      },
      // update-begin- --- author:wangshuai ------ date:20190929 ---- for:Jupload组件增加是否能够点击
      disabled:{
        type:Boolean,
        required:false,
        default: false
      },
      /**
       * update -- author:lvdandan -- date:20190219 -- for:Jupload组件增加是否返回url，
       * true：仅返回url
       * false：返回fileName filePath fileSize
       */
      returnUrl:{
        type:Boolean,
        required:false,
        default: false
      },
      number:{
        type:Number,
        required:false,
        default: 0
      },
    },
    watch:{
      value:{
        immediate: true,
        handler() {
          let val = this.value
          console.log(val)
          // if (val instanceof Array) {
          //   if(this.returnUrl){
          //     this.initFileList(val)
          //     // this.initFileList(val.join(','))
          //   }else{
          //     this.initFileListArr(val);
          //   }
          // } else {
          //   this.initFileList(val)
          // }
          
        }
      }
    },
    computed:{
      
    },
    created(){
      const token = '';
      //---------------------------- begin 图片左右换位置 -------------------------------------
      this.headers = {"X-Access-Token":token};
      this.containerId = 'container-ty-'+new Date().getTime();
      //---------------------------- end 图片左右换位置 -------------------------------------
    },

    methods:{
      initFileListArr(val){
        console.log(val)
        if(!val || val.length==0){
          this.fileList = [];
          return;
        }
        let fileList = [];
        for(var a=0;a<val.length;a++){
          let url = val[a].filePath?val[a].filePath:val[a].preview_url;
          fileList.push({
            uid:uidGenerator(),
            name:val[a].fileName?val[a].fileName:val[a].name,
            status: 'done',
            url: url,
            response:{
              status:"history",
              message:val[a].filePath?val[a].filePath:val[a].preview_url,
              id:val[a].id
            }
          })
        }
        this.fileList = fileList
      },
      initFileList(paths){
        console.log(paths)
        if(!paths || paths.length==0){
          //return [];
          // update-begin- --- author:os_chengtgen ------ date:20190729 ---- for:issues:326,Jupload组件初始化bug
          this.fileList = [];
          return;
          // update-end- --- author:os_chengtgen ------ date:20190729 ---- for:issues:326,Jupload组件初始化bug
        }
        let fileList = [];
        // let arr = paths.split(",")
        let arr = paths;
        for(var a=0;a<arr.length;a++){
          let url = arr[a].filePath?arr[a].filePath:arr[a].preview_url;
          fileList.push({
            uid:uidGenerator(),
            name:getFileName(arr[a].name),
            status: 'done',
            url: url,
            response:{
              status:"history",
              message:arr[a].filePath?arr[a].filePath:arr[a].preview_url,
              id:arr[a].id
            }
          })
        }
        this.fileList = fileList
      },
      handlePathChange(){
        // console.log(this.fileList)
        let uploadFiles = this.fileList
        let path = ''
        if(!uploadFiles || uploadFiles.length==0){
          path = ''
        }
        let arr = [];
        let arr_id = [];

        for(var a=0;a<uploadFiles.length;a++){
          // update-begin-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
          if(uploadFiles[a].status === 'done'&&uploadFiles[a].response.data ) {
            arr.push(uploadFiles[a].response.data.preview_url)
          }else if(uploadFiles[a].status === 'done'&&uploadFiles[a].response.message){
            arr.push(uploadFiles[a].response.message)
          }else{
            return;
          }
          // update-end-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
        }
        if(arr.length>0){
          path = arr.join(",")
        }
        this.$emit('change', path);
      },
      beforeUpload(file){
        console.log('beforeUpload',file)
        this.uploadGoOn=true
        var fileType = file.type;
        if(fileType.indexOf('image')<0){
          this.$message.warning('请上传图片');
          this.uploadGoOn=false
          return false;
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
          this.$message.error('上传的图片大小必须小于10M！');
          return false;
        }
        // getFileMd5(file).then(res=>{
        //   console.log(res)
        //   this.$emit('getMd5', res)
        // });
        //TODO 扩展功能验证文件大小
        return true
      },
      handleChange(info) {
        console.log("--文件列表改变--")
        console.log('handleChange', info)
        if(info){
          this.$emit('handleFile', info)
        }
        if(!info.file.status && this.uploadGoOn === false){
          info.fileList.pop();
        }
        let fileList = info.fileList
        if(info.file.status==='done'){
          if(this.number>0){
            fileList = fileList.slice(-this.number);
          }
          if(info.file.response.code==0){
            fileList = fileList.map((file) => {
              if (file.response&&file.response.data) {
                let reUrl = file.response.data.preview_url;
                file.url = reUrl;
                file.id = file.response.data.id;
              }
              if (file.response&&file.response.message) {
                let reUrl = file.response.message;
                file.url = reUrl;
                file.id = file.response.id;
              }
              return file;
            });
          }
          //this.$message.success(`${info.file.name} 上传成功!`);
        }else if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败.`);
        }else if(info.file.status === 'removed'){
          this.handleDelete(info.file)
        }
        this.fileList = fileList
        // console.log(fileList)
        if(info.file.status==='done' || info.file.status === 'removed'){
          //returnUrl为true时仅返回文件路径
          if(this.returnUrl){
            this.handlePathChange()
          }else{
            //returnUrl为false时返回文件名称、文件路径及文件大小
            this.newFileList = [];
            for(var a=0;a<fileList.length;a++){
              // update-begin-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
              if(fileList[a].status === 'done' ) {
                var fileJson = {
                  fileName:fileList[a].name,
                  filePath:fileList[a].url,
                  fileSize:fileList[a].size,
                  id:fileList[a].response.data?fileList[a].response.data.id:fileList[a].id
                };
                this.newFileList.push(fileJson);
              }else{
                return;
              }
              // update-end-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
            }
            this.$emit('change', this.newFileList);
          }
        }
        // console.log(this.fileList)
      },
      handleDelete(file){
        //如有需要新增 删除逻辑
        console.log(file)
      },
      getIndexByUrl(){
        for(let i=0;i<this.fileList.length;i++){
          if(this.fileList[i].url === this.currentImg || encodeURI(this.fileList[i].url) === this.currentImg){
            return i;
          }
        }
        return -1;
      }
    },
    mounted(){
      
    },
    model: {
      prop: 'value',
      event: 'change'
    }
  }
</script>

<style lang="scss" scoped>
.upload-box {
  width: 2.8vh;
  margin-left: 1vh;
  cursor: pointer;
}
</style>