<template>
  <div class="modal-view" v-if="visible">
    <div class="mask" @click="close"></div>
    <div class="main">
      <div class="close-view" @click="close">
        <a-icon type="close" style="fontSize: 2.3vh;color: #fff"/>
      </div>
      <div class="content">
        <div class="title">{{name}}</div>
        <iframe
          class="iframe-div"
          :src="url"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'lawsModal',
  data() {
    return {
      visible: false,
      url: '',
      name: '',
    }
  },
  components: {
    
  },
  mounted() {
    
  },
  methods: {
    // 初始化
    init(item) {
      this.visible = true;
      this.url = item.url;
      this.name = item.name;
    },
    // 关闭页面
    close(){
      this.visible = false;
      this.$emit('close')
    }

  }
}
</script>

<style lang="scss" scoped>
.modal-view {
  width: 100%;
  height: 100vh;
  .mask {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .8);
    z-index: 2;
  }
  .main {
    width: 140vh;
    height: 75vh;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: pink;
    background: url('../../../assets/img/modal-bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 4.5vh;
    z-index: 3;
    .close-view {
      position: absolute;
      right: 0vh;
      top: 0vh;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .title {
        color: #fff;
        font-size: 2.5vh;
        margin-bottom: 1vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 3.5vh;
      }
      .iframe-div {
        flex: 1;
        width: 100%;
      }
    }
  }
  
  
}
</style>
