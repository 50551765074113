<template>
  <div class="page-view">
    <Header></Header>
    <div class="main">
      <Left indexName="search" @click="initPage"></Left>
      <div class="right" v-if="!show_result">
        <div class="title">条例查询</div>
        <div class="search-box">
          <div class="search-input-box">
            <div class="input-box">
              <j-multi-select
                v-model="project_id"
                url="/api/screen/project/items"
                @input="sortChange"
              ></j-multi-select>
              <a-input :maxLength='256' placeholder="搜索内容" v-model="key" :disabled="is_upload" @pressEnter="handleSearch"/>
              <a-icon type="close" style="color: #fff;fontSize: 1.7vh;" @click="clearSearch" v-if="show_del"/>
              <search-upload @handleFile="handleFile" @getMd5="getMd5" :number="1"></search-upload>
            </div>
            <a-button type="primary" @click="handleSearch" :disabled="is_upload">搜索</a-button>
          </div>
          <a-progress :percent="percent" :show-info="false" v-if="is_upload"/>
          <div class="word-pool">
            <div class="handle-pool">
              <div class="flex-item">
                <img src="../../assets/img/hot-icon.png" alt="">
                <span>热门词条</span>
              </div>
              <div class="flex-item">
                <a-icon type="redo" />
                <div @click="getHotKey(1)">换一批</div>
              </div>
            </div>
            <div class="item-pool">
              <div class="center" v-if="hot_loading">
                <a-spin>
                  <a-icon slot="indicator" type="loading" style="font-size: 2.25vh" spin />
                </a-spin>
              </div>
              <div 
                class="item-word" 
                v-for="(item, index) in hot_list" 
                :key="index" 
                v-else
                @click="handleAssign(item)"
              >
                <ellipsis :length="8" tooltip>{{item.key}}</ellipsis>
              </div>
            </div>
          </div>
        </div>
      </div>
      <search-result-view ref="searchResultView" @close="handleClose" @openDetail="handleDetail"></search-result-view>
      <result-detail-view ref="resultDetailView" @close="backList"></result-detail-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/header.vue'
import Left from '@/components/Left/left.vue'
import JMultiSelect from '@/components/JMultiSelect/JMultiSelect.vue'
import SearchUpload from './module/searchUpload.vue'
import SearchResultView from './module/searchResultView.vue'
import ResultDetailView from './module/resultDetailView.vue'
import Ellipsis from "@/components/Ellipsis/Ellipsis";
export default {
  name:'search',
  data() {
    return {
      loading: false, //页面加载
      project_id: -1, //标签选择
      show_result: false, //显示搜索内容
      key: '', //输入的搜索内容
      percent: 0, //图片上传进度
      is_upload: false, //正在上传
      hot_list: [],
      hot_loading: false,
      page: 1, //热词列表下一次获取的页码
      no_more: false, //没有更多热词
      md5_str: '', //图片md5
      show_del: false,
    }
  },
  watch:{
    key:{
      immediate: true,
      handler(val) {
        console.log(val)
        this.show_del = val==''||!val ? false : true
      }
    }
  },
  components: {
    Header,
    Left,
    JMultiSelect,
    SearchUpload,
    Ellipsis,
    SearchResultView,
    ResultDetailView
  },
  mounted() {
    this.openDetail()
    this.getHotKey()
  },
  methods: {
    // 初始化页面
    initPage() {
      this.show_result = false;
      this.$nextTick(()=>{
        this.$refs.searchResultView.visible = false;
        this.$refs.resultDetailView.visible = false;
      })
    },
    // 直接打开详情
    openDetail() {
      let tdid = this.$route.query.tdid
      let id = null
      if(tdid) {
        this.show_result = true
        this.handleDetail(id, tdid)
      }
    },
    // 搜索热词
    handleAssign(item) {
      this.key = item.key.trim();
      this.handleSearch();
    },
    // 获取热词列表
    getHotKey(is_change=-1) {
      if(this.no_more) {
        this.$message.warning('没有更多热词')
        return
      }
      let params = {
        page: is_change==1 ? this.page : 1,
        page_size: 12
      }
      this.hot_list = [];
      this.hot_loading = true;
      this.$api.search.getHotKey(params).then(res =>{
        console.log(res)
        if(res.code==0){
          this.hot_list = res.data.data
          this.page = res.data.next_page_url ? params.page+1 : 1;
          this.no_more = res.data.current_page==1&&!res.data.next_page_url? true : false;
        }
      }).finally(() => {
        this.hot_loading = false;
      })
    },
    // 标签选择
    sortChange(e) {
      console.log(e)
      console.log(this.project_id)
    },
    // 获取图片md5
    getMd5(md5) {
      this.md5_str = md5;
      let params = {
        key: this.key,
        md5: this.md5_str,
        project_id: this.project_id,
      }
      this.show_result = true;
      this.$nextTick(()=>{
        this.$refs.searchResultView.init(params);
      })
    },
    // 获取图片上传状态
    handleFile(e) {
      this.is_upload = e.file.status == 'uploading' ? true : false;
      if(!this.is_upload){
        this.percent = 0;
      }
      if(e.event){
        this.percent = e.event.percent;
      }
      if(e.file.response&&e.file.response.code==0){
        let params = {
          image_id: e.file.response.data.id
        }
        this.show_result = true;
        this.$nextTick(()=>{
          this.$refs.searchResultView.init(params);
        })
        console.log('跳转搜索')
      }
    },
    // 清空搜索内容
    clearSearch() {
      this.key = ''
    },
    // 调起搜索
    handleSearch() {
      // if(!this.key) {   
      //   this.$message.warning('请输入要搜索的关键词');
      //   return
      // }
      // if(this.key.trim().length==0){
      //   this.$message.warning('输入的关键词不能为空');
      //   return
      // }
      let params = {
        key: this.key.trim(),
        project_id: this.project_id,
      }
      this.show_result = true;
      this.$nextTick(()=>{
        this.$refs.searchResultView.init(params);
      })
    },
    // 打开详情
    handleDetail(id, tdid) {
      this.$nextTick(()=>{
        this.$refs.searchResultView.show(false);
        this.$refs.resultDetailView.init(id, tdid);
      })
    },
    // 回到搜索页
    backList() {
      this.$nextTick(()=>{
        this.$refs.searchResultView.show(true);
      })
    },
    // 关闭搜索页面回调
    handleClose() {
      this.show_result = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-view {
  width: 100%;
  height: 100vh;
  .main {
    width: 100%;
    height: 89.77vh;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 4.93vh;
    background: linear-gradient(to bottom, #032142, #04305e);
    .right {
      flex: 1;
      height: 89.77vh;
      background: url('../../assets/img/bg.png') no-repeat;
      background-size: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 10.42vh;
      .title {
        font-family: 'PangMenZhengDao';
        font-size: 6.67vh;
        color: #fff;
        margin-bottom: 5.59vh;
        line-height: 10vh;
      }
      .search-box {
        width: 67.33vh;
        margin-bottom: 1vh;
        .search-input-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .input-box {
            width: 54.8vh;
            height: 5vh;
            border: 0.093vh solid #A6CCF5;
            border-radius: 0.75vh;
            padding: 1.02vh 1.49vh;
            display: flex;
            align-items: center;
            padding-left: 11vh;
            position: relative;
            ::v-deep .ant-select-arrow {
              right: -1.86vh;
              color: #fff;
            }
            ::v-deep .ant-select-selection__clear {
              display: none;
            }
            ::v-deep .ant-select {
              // width: auto !important;
              width: 20vh !important;
              position: absolute;
              left: 1vh;
            }
            ::v-deep .ant-select-selection__rendered {
              margin: 0;
              line-height: 0;
            }
            ::v-deep .ant-select-selection-selected-value {
              float: none;
              text-align: center;
              line-height: 3.7vh;
            }
            ::v-deep .ant-select-selection {
              background-color: #0962c3;
              min-width: 5.6vh;
              max-width: 7vh;
              // width: auto;
              border: 0;
              color: #fff;
              font-size: 1.3vh;
              height: 3.3vh;
              line-height: 3.3vh;
              padding: 0 0.9vh;
            }
            ::v-deep .ant-input {
              background-color: #00000000;
              color: #fff;
              font-size: 1.5vh;
              height: 3.3vh;
              line-height: 3.3vh;
              border: 0;
              width: auto;
              flex: 1;
              margin-right: 1vh;
            }
            
          }
          ::v-deep .ant-btn {
            height: 5vh;
            padding: 0 2.5vh;
            font-size: 1.86vh;
          }
        }
        .word-pool {
          width: 100%;
          margin-top: 4vh;
          .handle-pool {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.2vh;
            .flex-item {
              display: flex;
              align-items: center;
              font-size: 1.86vh;
              color: #fff;
              user-select: none;
              span {
                color: #FDB933;
              }
              div {
                cursor: pointer;
                font-size: 1.48vh;
                margin-left: 1vh;
              }
              img {
                margin-right: 1vh;
              }
            }
          }
          .item-pool {
            width: 100%;
            height: 9.5vh;
            overflow: hidden;
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            .item-word {
              color: #fff;
              height: 3.26vh;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.5vh;
              margin: 0 2.79vh 1.2vh 0; // 间隙为20px
              width: calc((100% - 14vh) / 6);   // 我这里一行显示4个 所以是/4  一行显示几个就除以几 
              padding: 0 .5vh;
              cursor: pointer;
              background: rgba(6, 86, 171, .32);
              &:nth-child(6) {
                margin-right: 0;
              } 
              &:nth-child(12) {
                margin-right: 0;
              }  
            }
            .item-word:hover {
              background: #1080F6;
            }
            .center {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
</style>
