<template>
  <div class="pagination-view">
    <a-pagination 
      v-model="current"
      :show-quick-jumper="showJumper"
      :total="total" 
      :page-size.sync="page_size"
      :disabled="disabled"
      :page-size-options="pageSizeOptions"
      :show-size-changer="showChanger"
      :showTotal="showTotal"
      @change="onChange"
      @showSizeChange="showSizeChange"
    />
  </div>
</template>

<script>
  export default {
    name: 'pagination',
    data(){
      return {
        current: 1,
        page_size: 10,
        showTotal: (total, range) => {
          return 
          // return range[0] + "-" + range[1] + " 共" + total + "条"
        },
      }
    },
    props:{
      // 是否可以改变 pageSize
      showChanger:{
        type: Boolean,
        required:false,
        default: false
      },
      // 是否显示快速跳转
      showJumper:{
        type: Boolean,
        required:false,
        default: false
      },
      // 是否禁用
      disabled:{
        type: Boolean,
        required:false,
        default: false
      },
      // 指定每页可以显示多少条
      pageSizeOptions:{
        type: Array,
        required: false,
        default: function () { return ['10', '20', '30', '40', '50'] }
      },
      // 当前页数
      page:{
        type: Number,
        required: false,
        default: 1
      },
      // 每页条数
      pageSize:{
        type: Number,
        required: false,
        default: 10
      },
      // 数据总数
      total:{
        type: Number,
        required: false,
        default: 500
      },
      // 组件尺寸，small为最小尺寸
      size:{
        type: String,
        required: false,
        default: ""
      }
    },
    watch:{
      page: {
        immediate: true,
        handler(val) {
          this.current = val
        },
      },
      pageSize: {
        immediate: true,
        handler(val) {
          this.page_size = val
        },
      },
    },
    computed:{
      
    },
    created(){

    },
    mounted(){
      
    },
    methods:{
      onChange(page, pageSize) {
        this.current = page
        this.page_size = pageSize
        this.$emit('change', {page, pageSize})
      },
      showSizeChange(page, pageSize) {
        this.current = 1
        this.page_size = pageSize
        this.$emit('change', {page: this.current, pageSize})
      }

    },
    
  }
</script>

<style lang="scss" scoped>
.pagination-view {
  width: 100%;
  display: flex;
  justify-content: center;
  ::v-deep .ant-pagination-item-active{
    border-color: #0870DC;
    background: #3395FE!important;
  }
  ::v-deep .ant-pagination-item {
    min-width: 2.98vh;
    height: 2.98vh;
    margin-right: 0.74vh;
    line-height: 2.79vh;
    border: 0.01vh solid #0870DC;
    border-radius: 0.38vh;
    background-color: #103154;
    color: #fff;
  }
  ::v-deep .ant-pagination-prev, 
  ::v-deep .ant-pagination-next, 
  ::v-deep .ant-pagination-jump-prev, 
  ::v-deep .ant-pagination-jump-next {
    min-width: 2.98vh;
    height: 2.98vh;
    line-height: 2.98vh;
    color: #fff;
    border-radius: 0.38vh;
  }
  ::v-deep .ant-pagination-prev, 
  ::v-deep .ant-pagination-jump-prev, 
  ::v-deep .ant-pagination-jump-next {
    margin-right: 0.74vh;
  }
  ::v-deep .ant-pagination {
    color: #fff;
    font-size: 1.3vh;
  }
  ::v-deep .ant-pagination-options {
    margin-left: 1.49vh;
  }
  ::v-deep .ant-pagination-disabled a, 
  ::v-deep .ant-pagination-disabled:hover a, 
  ::v-deep .ant-pagination-disabled:focus a, 
  ::v-deep .ant-pagination-disabled .ant-pagination-item-link, 
  ::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link, 
  ::v-deep .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: #fff;
    border-color: #0870DC;
  }
  ::v-deep .ant-pagination-prev .ant-pagination-item-link, 
  ::v-deep .ant-pagination-next .ant-pagination-item-link {
    font-size: 1.12vh;
    background-color: #103154;
    border: 0.01vh solid #0870DC;
    border-radius: 0.38vh;
    color: #fff;
  }
  ::v-deep .ant-pagination-item a {
    padding: 0 0.56vh;
    color: #fff;
  }
  ::v-deep .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, 
  ::v-deep .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #fff;
  }
  ::v-deep .ant-pagination-options-quick-jumper input {
    height: 2.98vh;
    padding: 0.38vh 1.02vh;
    color: #fff;
    font-size: 1.3vh;
    border: 0.01vh solid #0870DC;
    background-color: #103154;
    border-radius: 0.38vh;
    width: 4.65vh;
    margin: 0 0.75vh;
  }
  ::v-deep .ant-select {
    color: #fff;
  }
  ::v-deep .ant-select-selection--single {
    height: 2.98vh;
  }
  ::v-deep .ant-select-selection {
    background-color: #103154;
    border: 0.01vh solid #0870DC;
    border-radius: 0.38vh;
  }
  ::v-deep .ant-select-arrow {
    right: 1.03vh;
    margin-top: -0.56vh;
    color: #fff;
    font-size: 1.12vh;
  }
}
</style>