<template>
  <div class="res-view" v-if="visible">
    <div class="close-view" @click="close">
      <a-icon type="close" style="fontSize: 2.3vh;color: #fff"/>
    </div> 
    <div class="search-input-box">
      <div class="input-box">
        <a-input  ref="inputKey" :maxLength='256' placeholder="搜索内容" v-model="edit_key"/>
        <a-icon type="close" style="color: #fff;fontSize: 1.7vh;" @click="closeEdit" v-if="show_del"/>
        <search-upload @handleFile="handleFile" @getMd5="getMd5" :number="1"></search-upload>
      </div>
      <a-button type="primary" @click="handleKeySearch" :disabled="is_upload">搜索</a-button>
    </div>
    <a-progress :percent="percent" :show-info="false" v-if="is_upload"/>
    <div class="search-type-box">
      <div 
        class="item-type" 
        :class="type_list_active == item.id ? 'item-type-bg ' : ''"
        v-for="item in type_list" 
        :key="item.id"
        @click="typeChange(item)"
      >{{item.name}}</div>
    </div>
    <div class="total">已查询相关事项{{ipagination.total}}条</div>
    <div class="search-main">
      <div class="search-load" v-if="res_loading">
        <a-spin tip="加载中...">
          <a-icon slot="indicator" type="loading" style="font-size: 3.25vh" spin />
        </a-spin>
      </div>
      <div class="empty" v-if="search_content.length==0">
        <a-empty
          :image="empty_img"
          :image-style="{
            height: '7.6vh',
          }"
        >
          <span slot="description">暂无数据</span>
        </a-empty>
      </div>
      <div class="search-content" v-if="!res_loading&&search_content.length>0">
        <div class="item" v-for="(item, index) in search_content" :key="index" @click="openDetail(item, index)">
          <div class="head">
            <span class="title">{{item.name}}</span>
          </div>
          <div class="head">
            <span class="logo" v-for="(it, ind) in item.labels" :key="ind">#{{it}}</span>
          </div>
          <div class="no">
            <div>事项编号: {{item.code}}</div>
            <div class="hot">
              <img src="../../../assets/img/hot-icon2.png" alt="">
              <span>{{item.view_count}}</span>
            </div>
          </div>
          <div class="content">{{item.violation_rule}}</div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <pagination 
        v-if="search_content.length>0"
        :showJumper="ipagination.showQuickJumper" 
        :page="ipagination.current" 
        :total="ipagination.total"
        :showChanger="ipagination.showSizeChanger" 
        :pageSize="ipagination.pageSize"
        :pageSizeOptions="ipagination.pageSizeOptions"
        @change="onPageChange"
      ></pagination>
    </div>
    
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/pagination";
import SearchUpload from './searchUpload.vue'
export default {
  name:'searchResultView',
  data() {
    return {
      visible: false,
      type_list_active: -1,
      type_list: [],//标签列表
      search_content:[],//数据列表
      record: {},//搜索参数
      percent: 0, //图片上传进度
      is_upload: false, // 正在上传
      empty_img: require('../../../assets/img/empty.png'),
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 12,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['12', '24', '36', '48', '60']
      },
      res_loading: false,
      edit_key: '',//编辑关键词
      show_del: false
    }
  },
  components: {
    Pagination,
    SearchUpload
  },
  watch:{
    edit_key:{
      immediate: true,
      handler(val) {
        console.log(val)
        this.show_del = val==''||!val ? false : true
      }
    }
  },
  mounted() {
    
  },
  methods: {
    // 初始化
    init(params=-1) {
      this.visible = true;
      if(params!=-1){
        this.record = params;
        this.type_list_active = params.project_id;
        this.initPagination();
      }
      this.res_loading = false;
      this.edit_key = this.record.key;
      this.getTagList()
      if(params.image_id){
        this.getData(params);
        console.log('success')
      }else{
        this.getData();
      }
    },
    // 获取图片md5
    getMd5(md5) {
      this.getData(md5)
    },
    // 获取图片上传状态
    handleFile(e) {
      this.is_upload = e.file.status == 'uploading' ? true : false;
      if(!this.is_upload){
        this.percent = 0;
      }
      if(e.event){
        this.percent = e.event.percent;
      }
      if(e.file.response&&e.file.response.code==0){
        let params = {
          image_id: e.file.response.data.id
        }
        // this.show_result = true;
        this.$nextTick(()=>{
          this.init(params);
        })
        console.log('跳转搜索')
      }
    },
    // 清空编辑关键词
    closeEdit() {
      this.edit_key = '';
      this.record.key = '';
      this.getData()
      // this.$nextTick(()=>{
      //   this.$refs.inputKey.focus();
      // })
    },
    // 重新查询关键词
    handleKeySearch() {
      if(typeof(this.edit_key) === 'undefined') this.edit_key = ''
      this.record.key = this.edit_key.trim();
      this.getData()
    },
    // 获取标签列表
    getTagList() {
      this.$api.search.tagList().then(res =>{
        console.log(res)
        if(res.code==0){
          this.type_list = res.data;
          this.type_list.unshift({id: -1, name: "全部"})
        }
      }).finally(() => {
        
      })
    },
    // 获取数据列表
    getData(image_id=-1) {
      let params = Object.assign({}, this.record);
      params.project_id = params.project_id==-1?null:params.project_id;
      params.page = this.ipagination.current;
      params.page_size = this.ipagination.pageSize;
      console.log('111', this.ipagination.pageSize)
      if(image_id!=-1){
        // params.image_id = image_id;
        this.res_loading = true;
        this.$api.search.getSearchItemImage(params).then(res =>{
          console.log(res)
          if(res.code==0){
            this.search_content = res.data.data;
            this.ipagination.total = res.data.total;
          }
        }).finally(() => {
          this.res_loading = false;
        })
      }else{
        console.log('222', image_id)
        this.res_loading = true;
        this.$api.search.getSearchItem(params).then(res =>{
          console.log(res)
          if(res.code==0){
            this.search_content = res.data.data;
            this.ipagination.total = res.data.total;
          }
        }).finally(() => {
          this.res_loading = false;
        })
      }
    },
    // 初始化分页
    initPagination() {
      this.ipagination.current = 1;
      this.ipagination.total = 0;
      this.ipagination.pageSize = 12;
    },
    // 分页跳转
    onPageChange(e) {
      console.log(e)
      this.ipagination.current = e.page;
      this.ipagination.pageSize = e.pageSize;
      this.getData()
    },
    // 选择分类
    typeChange(item) {
      this.type_list_active = item.id;
      this.record.project_id = item.id;
      // this.initPagination();
      this.ipagination.current = 1;
      this.ipagination.total = 0;
      this.getData();
    },
    // 打开详情页
    openDetail(item, index) {
      this.$emit('openDetail', item.id)
    },
    // 关闭页面
    close(){
      this.visible = false;
      this.$emit('close')
    },
    // 页面显示或隐藏
    show(visible) {
      this.visible = visible;
    } 

  }
}
</script>

<style lang="scss" scoped>
.res-view {
  flex: 1;
  height: 83vh;
  margin-right: 5.2vh;
  border-radius: 1.5vh;
  background-color: rgba(9, 123, 244, .12);
  padding: 2vh 3vh 0;
  position: relative;
  display: flex;
  flex-direction: column;
  .close-view {
    position: absolute;
    right: 3vh;
    top: 3vh;
    cursor: pointer;
  }
  .search-input-box {
    width: 55vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vh;
    .input-box {
      width: 46.8vh;
      height: 4vh;
      border: 0.093vh solid #A6CCF5;
      border-radius: 0.75vh;
      padding-right: 1.49vh;
      display: flex;
      align-items: center;
      position: relative;
      ::v-deep .ant-select-arrow {
        right: -1.86vh;
        color: #fff;
      }
      ::v-deep .ant-select-selection__clear {
        display: none;
      }
      ::v-deep .ant-select {
        // width: auto !important;
        width: 20vh !important;
        position: absolute;
        left: 1vh;
      }
      ::v-deep .ant-select-selection__rendered {
        margin: 0;
        line-height: 0;
      }
      ::v-deep .ant-select-selection-selected-value {
        float: none;
        text-align: center;
        line-height: 3.7vh;
      }
      ::v-deep .ant-select-selection {
        background-color: #0962c3;
        min-width: 5.6vh;
        max-width: 7vh;
        // width: auto;
        border: 0;
        color: #fff;
        font-size: 1.3vh;
        height: 3.3vh;
        line-height: 3.3vh;
        padding: 0 0.9vh;
      }
      ::v-deep .ant-input {
        background-color: #00000000;
        color: #fff;
        font-size: 1.5vh;
        height: 3.3vh;
        line-height: 3.3vh;
        border: 0;
        width: auto;
        flex: 1;
        margin-right: 1vh;
      }
      
    }
    ::v-deep .ant-btn {
      font-size: 1.46vh;
    }
  }
  .search-type-box {
    width: 100%;
    padding-right: 3.5vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item-type {
      padding: 0.7vh 1.5vh .5vh;
      color: #fff;
      font-size: 1.68vh;
      margin-right: 1.5vh;
      margin-bottom: 1vh;
      cursor: pointer;
      user-select: none;
    }
    .item-type-bg {
      // background: url('../../../assets/img/type-bg.png') no-repeat;
      // background-size: 100% 100%;
      background: linear-gradient(180deg, rgba(97, 173, 255, 0) 0%, rgba(8, 185, 244, 0.64) 97.22%);
      border: 2px solid #33CCFF;
      border-radius: .8vh;
    }
    
  }
  .total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #33DCFE;
    font-size: 1.68vh;
    margin-top: -1vh;
    margin-bottom: 1vh;
  }
  .search-main {
    width: 100%;
    // height: 60.7vh;
    flex: 1;
    margin-bottom: 2vh;
    overflow-x: hidden;
    .search-load,
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .ant-spin {
        font-size: 1.68vh;
        line-height: 3vh;
      }
    }
    ::v-deep .ant-empty-description {
      font-family: 'YouSheBiaoTiHei';
      font-size: 2.5vh;
      line-height: 3.5vh;
      color: #fff;
    }
    .search-content {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .item {
        // width: 49.5vh;
        width: calc((100% - 5.96vh) / 3);;
        height: 22.5vh;
        background: url('../../../assets/img/search-item.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 2.98vh;
        margin-bottom: 2.23vh;
        padding: 1.49vh;
        color: #fff;
        font-size: 1.49vh;
        overflow: hidden;
        cursor: pointer;
        &:nth-child(3n) {
          margin-right: 0;
        }  
        .head {
          width: 100%;
          line-height: 3vh;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          .logo {
            padding: 0.47vh 1vh;
            background: rgba(8, 122, 240, 0.24);
            border-left: 0.5vh solid #3395FE;
            border-right: 0.5vh solid #3395FE;
            margin-right: .8vh;
          }
          .title {
            font-size: 1.68vh;
            font-weight: 600;
          }
        }
        .no {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // line-height: 3.5vh;
          margin: 1vh 0vh;
          padding-right: 1vh;
          .hot {
            display: flex;
            align-items: center;
            img {
              width: 1.3vh;
              margin-right: .8vh;
            }
          }
        }
        .content {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 3vh;
        }
      }
      .item:hover {
        background: url('../../../assets/img/search-item-active.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .pagination {
    width: 100%;
    height: 3.3vh;
    margin-bottom: 1.5vh;
  }
}
</style>
